import * as React from "react";
import dayjs from "dayjs";
const CheckInOutContext = React.createContext();
export const useCheckInOutContext = () => React.useContext(CheckInOutContext);
import { GetAgreementById } from "../services/AgreementServices";
import {
  getChassisAssignedByAgrementId,
  getChassisInspections,
} from "../services/ChassisServices";

import { useSnackBar } from "./SnackBarContext";
import Cookies from "universal-cookie";
export const CheckInOutProvider = ({ children }) => {
  const { handleRenderSnackBar } = useSnackBar();
  const [loading, setLoading] = React.useState(true);
  const cookies = new Cookies();
  const [onlyChassisIngate, setOnlyChassisIngate] = React.useState(false);
  const [ownerScacCode, setOwnerScacCode] = React.useState(null);
  const [agreementData, setAgreementData] = React.useState(null);
  const [carrierScacCode, setCarrierScacCode] = React.useState(null);
  const [ingateDateTime, setIngateDateTime] = React.useState({
    date: dayjs(),
    time: dayjs(),
  });
  const [
    chassisAssignedToAntoherAgreement,
    setChassisAssignedToAntoherAgreement,
  ] = React.useState(false);
  const [outgateDateTime, setOutgateDateTime] = React.useState({
    date: dayjs(),
    time: dayjs(),
  });
  const [chassisAssigned, setChassisAssigned] = React.useState(true);
  const [chassisAssignedToThisAgreement, setChassisAssignedToThisAgreement] =
    React.useState([]);

  const [transactionPayload, setTransactionPayload] = React.useState({
    transaction_id: null,
    location: null,
    license: {
      id: null,
      driver_name: null,
      driver_license: null,
      scac_code: null,
      truck: null,
    },
    ingate: {
      arrive_at: null,
      transaction_type: "bobtail",
      container_number: null,
      chassis_number: null,
      trailer_number: null,
      empty: true,
      flip: false,
      spot_number: null,
      move_type: "import",
      ssl: null,
      size: null,
      last_free_day: null,
      workflow: "manual",
      created_by: null,
    },
    outgate: {
      departure_at: null,
      transaction_type: "chassis",
      container_number: null,
      chassis_number: null,
      trailer_number: null,
      empty: true,
      flip: false,
      spot_number: null,
      workflow: "manual",
      created_by: null,
    },
  });

  const getParamFromUrl = (paramName) => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get(paramName);
  };

  function renderMessageAndRedirect(message, redirectUrl) {
    handleRenderSnackBar(message, "error");
    setTimeout(() => {
      window.location.href = redirectUrl;
      setLoading(false);
    }, 3000);
  }

  function handleCheckChassisInUrl(ownerScacCode) {
    const chassisIn = getParamFromUrl("Chassis");
    if (chassisIn) {
      setTransactionPayload((prev) => ({
        ...prev,
        ingate: {
          ...prev.ingate,
          transaction_type: "chassis",
          chassis_number: chassisIn,
        },
        outgate: {
          ...prev.outgate,
          transaction_type: "bobtail",
        },
        license: {
          ...prev.license,
          scac_code: ownerScacCode ? ownerScacCode : "",
        },
      }));
      setOnlyChassisIngate(true);
    } else {
      setTransactionPayload((prev) => ({
        ...prev,
        license: {
          ...prev.license,
          scac_code: ownerScacCode ? ownerScacCode : "",
        },
      }));
    }
  }
  function handleGetAgreementData() {
    const AgreementId = getParamFromUrl("AgreementId");

    GetAgreementById(AgreementId)
      .then((data) => {
        if (
          data.data.OwnerScacCode !== cookies.get("sessionCosmos").scac_code
        ) {
          renderMessageAndRedirect(
            "You user is not authorized to check in/out with this agreement, you have to use user as owner",
            "/HomeView"
          );
        } else if (data.data.Status !== "Activated") {
          renderMessageAndRedirect(
            "This agreement is not activated, you can't check in/out with it",
            "/HomeView"
          );
        } else if (
          data.data.Service.ChassisRented === data.data.Service.ChassisOutgated
        ) {
          handleRenderSnackBar(
            "All chassis rented for this agreement are already outgated, you are only allowed to check in Chassis Only",
            "error"
          );

          setTransactionPayload((prev) => ({
            ...prev,
            ingate: {
              ...prev.ingate,
              transaction_type: "chassis",
            },
            outgate: {
              ...prev.outgate,
              transaction_type: "bobtail",
            },
          }));

          setAgreementData(data.data);
          setOwnerScacCode(data.data.OwnerScacCode);
          setOnlyChassisIngate(true);
          setTimeout(() => {
            handleCheckChassisInUrl(data.data.OwnerScacCode);
          }, 1000);

          setLoading(false);
        } else {
          handleRenderSnackBar(
            data.data.Service.ChassisRented -
              data.data.Service.ChassisOutgated +
              " chassis are remaining to be outgated with this agreement",
            "success"
          );
          handleGetChassisInspections(data.data.OwnerScacCode);
          setAgreementData(data.data);
          setOwnerScacCode(data.data.OwnerScacCode);
          setTimeout(() => {
            handleCheckChassisInUrl(data.data.OwnerScacCode);
          }, 1000);
        }
      })
      .catch((error) => {
        handleRenderSnackBar(
          "Error getting agreement data, this agreement is not for chassis rental",
          "error"
        );
        setTimeout(() => {
          window.location.href = "/HomeView";
          setLoading(false);
        }, 3000);
      });
  }

  function handleGetChassisInspections(scacCode) {
    getChassisInspections(scacCode).then((response) => {
      if (response.Success && response.Data.length > 0) {
        setChassisAssignedToThisAgreement(response.Data);
      }
    });
  }

  function handleSetCarrierScacCode() {
    const scac_code = getParamFromUrl("CarrierScacCode");
    setCarrierScacCode(scac_code);
    setTransactionPayload((prev) => ({
      ...prev,
      ingate: {
        ...prev.ingate,
        created_by: cookies.get("sessionCosmos").email[0],
      },
      outgate: {
        ...prev.outgate,
        created_by: cookies.get("sessionCosmos").email[0],
      },
    }));
  }
  React.useEffect(() => {
    handleGetAgreementData();
    handleSetCarrierScacCode();
  }, []);

  React.useEffect(() => {
    switch (transactionPayload.ingate.transaction_type) {
      case "chassis":
        const chassisIngate = getParamFromUrl("Chassis");

        setTransactionPayload((prev) => ({
          ...prev,
          ingate: {
            ...prev.ingate,
            container_number: null,
            trailer_number: null,
            chassis_number: chassisIngate ? chassisIngate : null,
            empty: true,
            flip: false,
            move_type: "import",
            ssl: null,
            size: null,
            last_free_day: null,
          },
          outgate: {
            ...prev.outgate,
            transaction_type: "bobtail",
            chassis_number: null,
          },
        }));
        break;
      case "bobtail":
        setTransactionPayload((prev) => ({
          ...prev,
          ingate: {
            ...prev.ingate,
            container_number: null,
            chassis_number: null,
            trailer_number: null,
            empty: true,
            flip: false,
            spot_number: null,
            move_type: "import",
            ssl: null,
            size: null,
            last_free_day: null,
          },
          outgate: {
            ...prev.outgate,
            transaction_type: "chassis",
          },
        }));
        break;
      default:
        break;
    }
  }, [transactionPayload.ingate.transaction_type]);

  React.useEffect(() => {
    switch (transactionPayload.outgate.transaction_type) {
      case "chassis":
        setTransactionPayload((prev) => ({
          ...prev,
          ingate: {
            ...prev.ingate,
            transaction_type: "bobtail",
            chassis_number: null,
          },
          outgate: {
            ...prev.outgate,
            container_number: null,
            chassis_number: null,
            trailer_number: null,
            empty: true,
            flip: false,
            spot_number: null,
          },
        }));
        break;
      case "bobtail":
        const chassisIngate = getParamFromUrl("Chassis");
        setTransactionPayload((prev) => ({
          ...prev,
          ingate: {
            ...prev.ingate,
            transaction_type: "chassis",
            chassis_number: chassisIngate ? chassisIngate : null,
          },
          outgate: {
            ...prev.outgate,
            container_number: null,
            chassis_number: null,
            trailer_number: null,
            empty: true,
            flip: false,
            spot_number: null,
          },
        }));
        break;
      default:
        break;
    }
  }, [transactionPayload.outgate.transaction_type]);

  React.useEffect(() => {
    const addMinute = ingateDateTime.time;
    const updatedTime = addMinute.add(1, "minute");

    setOutgateDateTime((prev) => ({
      ...prev,
      date: ingateDateTime.date,
      time: updatedTime,
    }));
  }, [ingateDateTime]);
  return (
    <CheckInOutContext.Provider
      value={{
        ingateDateTime,
        outgateDateTime,
        loading,
        onlyChassisIngate,
        transactionPayload,
        ownerScacCode,
        agreementData,
        carrierScacCode,
        setIngateDateTime,
        setOutgateDateTime,
        setTransactionPayload,
        setLoading,
        chassisAssignedToThisAgreement,
        chassisAssigned,
        setChassisAssigned,
        chassisAssignedToAntoherAgreement,
        setChassisAssignedToAntoherAgreement,
      }}
    >
      {children}
    </CheckInOutContext.Provider>
  );
};
