import React from "react";
import {
  Typography,
  Paper,
  Button,
  TextField,
  Autocomplete,
  LinearProgress,
  Backdrop,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./CheckIn.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useCheckInOutContext } from "../../context/CheckInOutContext";
import { CheckInChassisRental } from "../../services/CheckInServices";
import { CheckOutChassisRental } from "../../services/CheckOutServices";
import { GetCarrierByScacCode } from "../../services/CarrierServices";
import {
  StartChassisClock,
  StopChassisClock,
  AssignInspectionChassisIn,
} from "../../services/ChassisServices";
import { useSnackBar } from "../../context/SnackBarContext";
import dayjs from "dayjs";
import { Start } from "@mui/icons-material";

const CheckIn = () => {
  const {
    outgateDateTime,
    ingateDateTime,
    onlyChassisIngate,
    transactionPayload,
    setTransactionPayload,
    loading,
    setLoading,
    agreementData,
    carrierScacCode,
    setIngateDateTime,
    chassisAssigned,
    chassisAssignedToAntoherAgreement,
  } = useCheckInOutContext();
  const { handleRenderSnackBar } = useSnackBar();
  const [minDateCheckIn, setMinDateCheckIn] = React.useState(new Date());
  const url =
    process.env.REACT_APP_IMAGES_BANK + "yms-images/app-bar-images/";
  const urlButtons =
    process.env.REACT_APP_IMAGES_BANK + "yms-images/btn-icons/";
  const [width, setWidth] = React.useState(window.innerWidth);
  const [confirmAvailable, setConfirmAvailable] = React.useState(false);

  function handleChangeTypeInGate(event) {
    setTransactionPayload({
      ...transactionPayload,
      ingate: {
        ...transactionPayload.ingate,
        transaction_type: event.target.value,
      },
    });
  }
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  //VALIDATORS-----------------------------------------------------
  function handleEvaluateConfirm() {
    if (
      transactionPayload.ingate.transaction_type === "bobtail" &&
      transactionPayload.outgate.chassis_number &&
      transactionPayload.license.scac_code.length === 4 &&
      transactionPayload.location &&
      ingateDateTime.date &&
      ingateDateTime.time &&
      !chassisAssignedToAntoherAgreement
    ) {
      setConfirmAvailable(true);
    } else if (
      transactionPayload.ingate.transaction_type === "chassis" &&
      transactionPayload.ingate.chassis_number &&
      transactionPayload.license.scac_code &&
      transactionPayload.license.scac_code.length === 4 &&
      transactionPayload.location &&
      ingateDateTime.date &&
      ingateDateTime.time
    ) {
      setConfirmAvailable(true);
    } else {
      setConfirmAvailable(false);
    }
  }

  //CONSTRUCTORS PAYLOADS-----------------------------------------------------
  function handleAssignInspectionChassisInPayloadConstructor() {
    const payloadToAssignInspection = {
      Chassis: {
        TypeChassis: agreementData.Service.hassisRentedCodeName.CodeName,
        OwnerScacCode: transactionPayload.license.scac_code,
        ChassisNumber:
          !chassisAssigned &&
          transactionPayload.outgate.transaction_type === "chassis"
            ? transactionPayload.outgate.chassis_number
            : transactionPayload.ingate.chassis_number,
      },
      Reason:
        !chassisAssigned &&
        transactionPayload.outgate.transaction_type === "chassis"
          ? "Out"
          : "In",
      Agreement: {
        AgreementId: agreementData.AgreementId,
        CarrierScac: agreementData.CarrierContact.ScacCode,
      },
      DeliveryDate: dayjs(ingateDateTime.date).format("YYYY-MM-DD HH:mm:ss"),
    };
    return payloadToAssignInspection;
  }

  function handleCheckOutPayloadConstructor() {
    const payload = transactionPayload;

    payload.action = "out";
    const formattedDateTime =
      dayjs(ingateDateTime.date).format("YYYY-MM-DD") +
      "T" +
      dayjs(ingateDateTime.time).format("HH:mm:ss");
    payload.ingate.arrive_at = new Date(formattedDateTime).toISOString();
    const dateTimeWithAddedMinute = dayjs(formattedDateTime).add(1, "minute");
    payload.outgate.departure_at = new Date(
      dateTimeWithAddedMinute
    ).toISOString();

    return payload;
  }

  function handleCheckInPayloadConstructor() {
    const payload = transactionPayload;

    payload.action = "in";
    const formattedDateTime =
      dayjs(ingateDateTime.date).format("YYYY-MM-DD") +
      "T" +
      dayjs(ingateDateTime.time).format("HH:mm:ss");

    payload.ingate.arrive_at = new Date(formattedDateTime).toISOString();
    if (transactionPayload.ingate.transaction_type !== "chassis") {
      payload.ingate.chassis_number = null;
    } else if (transactionPayload.outgate.transaction_type !== "chassis") {
      payload.outgate.chassis_number = null;
    }
    return payload;
  }

  async function handleStartChassisClockPayloadConstructor() {
    const carrierId = await handleGetCarrierId(); // Espera a que se resuelva la promesa
    const payload = {
      Carrier: {
        ScacCode: carrierScacCode,
        IdCarrier: carrierId,
      },
      Chassis: {
        TypeChassis: agreementData.Service.hassisRentedCodeName.CodeName,
        CarrierScacCode: transactionPayload.license.scac_code,
        ChassisNumber: transactionPayload.outgate.chassis_number,
      },
      AgreementId: agreementData.AgreementId,
      StartTime:
        dayjs(outgateDateTime.date).format("YYYY-MM-DD") +
        " " +
        dayjs(outgateDateTime.time).format("HH:mm:ss"),
    };

    return payload;
  }

  async function handleGetCarrierId() {
    try {
      const data = await GetCarrierByScacCode(carrierScacCode);
      if (data) {
        return data.idCosmos;
      } else {
        throw new Error("Carrier not found");
      }
    } catch (error) {
      handleRenderSnackBar("Carrier not found", "error");
      setLoading(false);
      return null;
    }
  }

  //CLOCK FUNCTIONS-----------------------------------------------------
  async function handleStartChassisClock() {
    const payload = await handleStartChassisClockPayloadConstructor();
    StartChassisClock(payload)
      .then((data) => {
        if (data.IdTicket) {
          handleRenderSnackBar(
            "The chassis clock has started with ticket: " + data.IdTicket,
            "success"
          );
          if (
            !chassisAssigned &&
            transactionPayload.outgate.transaction_type === "chassis"
          ) {
            handleAssignChassisIn();
          } else {
            setLoading(false);
            window.location.reload();
          }
          return;
        } else {
          handleRenderSnackBar(data[0].ErrorMessage, "error");
          setLoading(false);
        }
      })
      .catch((error) => {
        handleRenderSnackBar(error[0].ErrorMessage, "error");
        setLoading(false);
      });
  }

  async function handleStopChassisClock() {
    try {
      const transactionPayloadFromCheckOut = handleCheckOutPayloadConstructor();
      const data = await StopChassisClock(transactionPayloadFromCheckOut);
      if (data.success) {
        return true;
      } else {
        return data;
      }
    } catch (error) {
      handleRenderSnackBar("Stop Chassis Clock failed", "error");
      setLoading(false);
      throw error; // Lanzar el error para que pueda ser capturado por quien llama
    }
  }

  //ASSIGN INSPECTION CHASSIS -----------------------------------------------------
  function handleAssignChassisIn() {
    const payload = handleAssignInspectionChassisInPayloadConstructor();
    AssignInspectionChassisIn(payload)
      .then((data) => {
        if (data.IdInspect) {
          handleRenderSnackBar(
            transactionPayload.ingate.transaction_type === "chassis"
              ? "Chassis assigned successfully"
              : "Chassis delivered successfully",
            "success"
          );

          setTimeout(() => {
            setLoading(false);
            const url = new URL(window.location.href);
            url.searchParams.delete("Chassis");
            window.location.href = url.toString();
          }, 2000);
        } else {
          handleRenderSnackBar(data.Message, "error");
          setLoading(false);
        }
      })
      .catch((error) => {
        handleRenderSnackBar("Assign Chassis failed", "error");
      });
  }

  //CHECK IN / OUT FUNCTIONS-----------------------------------------------------
  function handleCheckOut() {
    const payload = handleCheckOutPayloadConstructor();
    CheckOutChassisRental(payload)
      .then((data) => {
        if (data.success) {
          if (transactionPayload.ingate.transaction_type === "chassis") {
            handleRenderSnackBar("Clock stopped successfully", "success");
            handleAssignChassisIn();

            return;
          }
          handleStartChassisClock(payload);
        } else {
          handleRenderSnackBar(data.message, "error");
          setLoading(false);
        }
      })
      .catch((error) => {
        handleRenderSnackBar("Check Out failed", "error");
        setLoading(false);
      });
  }

  async function CheckInConfirm() {
    setLoading(true);
    try {
      if (transactionPayload.ingate.transaction_type === "chassis") {
        const stopClockCompleted = await handleStopChassisClock();
        if (stopClockCompleted !== true) {
          handleRenderSnackBar(stopClockCompleted[0].ErrorMessage, "error");
          setLoading(false);
          return;
        }
      }
      const payload = handleCheckInPayloadConstructor();
      const data = await CheckInChassisRental(payload);
      if (data.success) {
        handleCheckOut();
      } else {
        handleRenderSnackBar(data.message, "error");
        setLoading(false);
      }
    } catch (error) {
      handleRenderSnackBar("Check In failed", "error");
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleEvaluateConfirm();
  }, [transactionPayload, ingateDateTime]);

  React.useEffect(() => {
    if (agreementData) {
      setMinDateCheckIn(new Date(agreementData.AgreementDates.ValidFromUTC));
      if (new Date(agreementData.AgreementDates.ValidFromUTC) > new Date()) {
        setIngateDateTime({
          ...ingateDateTime,
          date: dayjs(new Date(agreementData.AgreementDates.ValidFromUTC)),
        });
      }
    }
  }, [agreementData]);

  return (
    <>
      <Paper className="CheckIn-paper-container" elevation={12}>
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="CheckIn-title-container-grid"
            container
          >
            {width > 600 && (
              <Grid
                xs={0}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="CheckIn-leftIcon-grid"
              >
                <img
                  src={url + "icono In gate.png"}
                  alt="check-in"
                  className="CheckIn-left-image"
                />
              </Grid>
            )}
            <Grid
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              className="CheckIn-title-grid-item"
            >
              <Typography className="CheckIn-title-text">in gate</Typography>
            </Grid>
            {width > 600 && (
              <Grid
                xs={0}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="CheckIn-rigthIcon-grid"
              >
                <img
                  src={url + "Dibujo In gate.png"}
                  alt="check-in"
                  className="CheckIn-rigth-image"
                />
              </Grid>
            )}
          </Grid>{" "}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            {loading && <LinearProgress className="CheckOut-linearprogress" />}
          </Grid>{" "}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="CheckIn-tabs-container-grid"
            container
            spacing={3}
          >
            {" "}
            <Grid
              xs={12}
              sm={4}
              md={6}
              lg={4}
              xl={4}
              className="CheckIn-tabs-button-grid-item"
            >
              <Button
                onClick={(e) => handleChangeTypeInGate(e)}
                className={
                  transactionPayload.ingate.transaction_type === "bobtail"
                    ? "CheckIn-tab-button-selected"
                    : "CheckIn-tab-button"
                }
                fullWidth
                disabled={onlyChassisIngate}
                value="bobtail"
              >
                BOBTAIL
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              md={6}
              lg={4}
              xl={4}
              className="CheckIn-tabs-button-grid-item"
            >
              <Button
                fullWidth
                onClick={(e) => handleChangeTypeInGate(e)}
                className={
                  transactionPayload.ingate.transaction_type === "chassis"
                    ? "CheckIn-tab-button-selected"
                    : "CheckIn-tab-button"
                }
                value="chassis"
              >
                CHASSIS ONLY
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              md={12}
              lg={4}
              xl={4}
              className="CheckIn-tabs-button-grid-item"
            >
              <Button
                onClick={(e) => handleChangeTypeInGate(e)}
                className={
                  transactionPayload.ingate.transaction_type === "trailer"
                    ? "CheckIn-tab-button-selected"
                    : "CheckIn-tab-button"
                }
                fullWidth
                disabled
                value="trailer"
              >
                TRAILER
              </Button>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              className="fit-content"
            >
              {(transactionPayload.ingate.transaction_type === "bobtail" ||
                transactionPayload.ingate.transaction_type === "common") && (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="CheckIn-grid-container-input"
                >
                  <TextField
                    fullWidth
                    size="small"
                    label="Container Number"
                    variant="outlined"
                    value={transactionPayload.ingate.container_number || ""}
                    disabled={
                      transactionPayload.ingate.transaction_type !== "common"
                    }
                  />
                </Grid>
              )}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-chassis-input"
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Chassis Number"
                  variant="outlined"
                  onChange={(e) =>
                    setTransactionPayload({
                      ...transactionPayload,
                      ingate: {
                        ...transactionPayload.ingate,
                        chassis_number: e.target.value.toUpperCase(),
                      },
                    })
                  }
                  value={transactionPayload.ingate.chassis_number || ""}
                  disabled={
                    transactionPayload.ingate.transaction_type !== "common" &&
                    transactionPayload.ingate.transaction_type !== "chassis"
                  }
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="CheckIn-grid-chassis-input"
              >
                <Button
                  fullWidth
                  className={
                    transactionPayload.ingate.move_type === "import"
                      ? "CheckIn-tab-button-selected"
                      : "CheckIn-tab-button"
                  }
                  disabled={
                    transactionPayload.ingate.transaction_type !== "common"
                  }
                >
                  IMPORT
                </Button>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="CheckIn-grid-chassis-input"
              >
                <Button
                  fullWidth
                  className={
                    transactionPayload.ingate.move_type === "export"
                      ? "CheckIn-tab-button-selected"
                      : "CheckIn-tab-button"
                  }
                  disabled={
                    transactionPayload.ingate.transaction_type !== "common"
                  }
                >
                  EXPORT
                </Button>
              </Grid>

              <Grid
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className="CheckIn-grid-chassis-input"
              />
              {(transactionPayload.ingate.transaction_type === "bobtail" ||
                transactionPayload.ingate.transaction_type === "common") && (
                <>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    container
                    spacing={2}
                    className="CheckIn-grid-chassis-input"
                  >
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        disabled={
                          transactionPayload.ingate.transaction_type !==
                          "common"
                        }
                        disablePortal
                        id="combo-box-demo"
                        options={[]}
                        renderInput={(params) => (
                          <TextField {...params} label="SSL" size="small" />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        disabled={
                          transactionPayload.ingate.transaction_type !==
                          "common"
                        }
                        disablePortal
                        id="combo-box-demo"
                        options={[]}
                        renderInput={(params) => (
                          <TextField {...params} label="Size" size="small" />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    container
                    spacing={2}
                    className="CheckIn-grid-chassis-input"
                  >
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        size="small"
                      >
                        <MobileDatePicker
                          disabled
                          label="Last Free Day"
                          inputFormat="MM/dd/yyyy"
                          value={null}
                          onChange={() => {}}
                          size="small"
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>{" "}
                </>
              )}
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={5}
              xl={5}
              display={"flow"}
            >
              {(transactionPayload.ingate.transaction_type === "bobtail" ||
                transactionPayload.ingate.transaction_type === "common") && (
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="CheckIn-grid-container-empty-flip-buttons"
                >
                  <Grid
                    container
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="CheckIn-empty-loaded-grid-button"
                  >
                    <Button
                      disabled
                      variant="contained"
                      fullWidth
                      className={`CheckIn-empty-loaded-button ${
                        transactionPayload.ingate.empty
                          ? "CheckIn-empty-loaded-activated"
                          : ""
                      }`}
                      onClick={() =>
                        setTransactionPayload({
                          ...transactionPayload,
                          ingate: {
                            ...transactionPayload.ingate,
                            empty: !transactionPayload.ingate.empty,
                          },
                        })
                      }
                    >
                      <img
                        src={
                          urlButtons +
                          (transactionPayload.ingate.empty
                            ? "Empty.png"
                            : "Loaded.png")
                        }
                        alt="empty"
                        className="CheckIn-empty-loaded-image"
                      />
                    </Button>
                  </Grid>
                  <Grid
                    container
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="CheckIn-flip-grid-button"
                  >
                    {" "}
                    <Button
                      disabled
                      variant="contained"
                      fullWidth
                      className={`CheckIn-flip-button ${
                        transactionPayload.ingate.flip
                          ? "CheckIn-flip-button-activated"
                          : ""
                      }`}
                      onClick={() =>
                        setTransactionPayload({
                          ...transactionPayload,
                          ingate: {
                            ...transactionPayload.ingate,
                            empty: !transactionPayload.ingate.flip,
                          },
                        })
                      }
                    >
                      {" "}
                      <img
                        src={
                          urlButtons +
                          (transactionPayload.ingate.flip
                            ? "Flip.png"
                            : "No flip.png")
                        }
                        alt="flip"
                        className={"CheckIn-flip-image"}
                      />
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-ingate-date"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    label="In Gate Date"
                    minDate={dayjs(minDateCheckIn)}
                    inputFormat="yyyy-MM-dd"
                    value={ingateDateTime.date}
                    onChange={(e) => {
                      setIngateDateTime({
                        ...ingateDateTime,
                        date: e,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>{" "}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-ingate-time"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    label="In Gate Time"
                    sx={{ width: "100%" }}
                    value={ingateDateTime.time}
                    onChange={(e) => {
                      setIngateDateTime({
                        ...ingateDateTime,
                        time: e,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="CheckIn-grid-container-confirm-button"
          >
            <Grid xs={12} sm={6} md={8} lg={9} xl={9}></Grid>
            <Grid xs={12} sm={6} md={4} lg={3} xl={3}>
              <Button
                fullWidth
                variant="contained"
                disabled={!confirmAvailable}
                className="CheckIn-confirm-button"
                onClick={CheckInConfirm}
              >
                CONFIRM
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CheckIn;
