// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ChassisRentalLogger-Root-container {
  height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
  max-width: 100%;
}

body .ChassisRentalLoger-container-width {
  height: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
}

body .MuiContainer-maxWidthLg {
  max-width: 1451px;
}

body .MuiContainer-maxWidthXl {
  max-width: 1451px;
}

body .ChassisRentalLogger-Root-loading {
  background-color: rgba(255, 255, 255, 0.698);
  z-index: 20; /* Asegúrate de que el valor sea mayor que cualquier otro z-index en la página */

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed; /* Posiciona el loader en la parte superior de la página */
  top: 72px;
  left: 63px;
  width: 100%; /* Cubre todo el ancho de la pantalla */
  height: 100%; /* Cubre todo el alto de la pantalla */
}

body .ChassisRentalLogger-circular-progress {
  color: #fc5629;
  scale: 2.5;
}
`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,4CAA4C;EAC5C,WAAW,EAAE,gFAAgF;;EAE7F,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe,EAAE,0DAA0D;EAC3E,SAAS;EACT,UAAU;EACV,WAAW,EAAE,uCAAuC;EACpD,YAAY,EAAE,sCAAsC;AACtD;;AAEA;EACE,cAAc;EACd,UAAU;AACZ","sourcesContent":["body .ChassisRentalLogger-Root-container {\n  height: calc(100vh - 72px);\n  display: flex;\n  justify-content: center;\n  max-width: 100%;\n}\n\nbody .ChassisRentalLoger-container-width {\n  height: 100%;\n  padding-top: 60px;\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  max-width: 100%;\n}\n\nbody .MuiContainer-maxWidthLg {\n  max-width: 1451px;\n}\n\nbody .MuiContainer-maxWidthXl {\n  max-width: 1451px;\n}\n\nbody .ChassisRentalLogger-Root-loading {\n  background-color: rgba(255, 255, 255, 0.698);\n  z-index: 20; /* Asegúrate de que el valor sea mayor que cualquier otro z-index en la página */\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  position: fixed; /* Posiciona el loader en la parte superior de la página */\n  top: 72px;\n  left: 63px;\n  width: 100%; /* Cubre todo el ancho de la pantalla */\n  height: 100%; /* Cubre todo el alto de la pantalla */\n}\n\nbody .ChassisRentalLogger-circular-progress {\n  color: #fc5629;\n  scale: 2.5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
