// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ChassisRentalLoger-ChassisRentalFieldRow-container {
  min-width: 100%;
  height: fit-content;
  padding: 40px 0px 40px 0px;
}

body .ChassisRentalFieldRow-supra-button {
  background-color: #fc5629;
  color: white;
  border-radius: 8px;
}

body .ChassisRentalFieldRow-validate-license-button {
  background-color: #3ac993;
  color: white;
  border-radius: 8px;
}

body .ChassisRentalFieldRow-scaccode-textfield{
  border-radius: 8px;
}

body .ChassisRentalFieldRow-location-select{
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/containers/ChassisRentalFieldRow/ChassisRentalFieldRow.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["body .ChassisRentalLoger-ChassisRentalFieldRow-container {\n  min-width: 100%;\n  height: fit-content;\n  padding: 40px 0px 40px 0px;\n}\n\nbody .ChassisRentalFieldRow-supra-button {\n  background-color: #fc5629;\n  color: white;\n  border-radius: 8px;\n}\n\nbody .ChassisRentalFieldRow-validate-license-button {\n  background-color: #3ac993;\n  color: white;\n  border-radius: 8px;\n}\n\nbody .ChassisRentalFieldRow-scaccode-textfield{\n  border-radius: 8px;\n}\n\nbody .ChassisRentalFieldRow-location-select{\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
