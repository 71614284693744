import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const GetAgreementById = async (AgreementId) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_AGREEMENT_BY_ID,
      method: "GET",
      params: { AgreementId },
      nameEvent: "ChassisRentalLogger-GetAgreementById",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
