import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const StartChassisClock = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_START_CHASSIS_CLOCK,
      method: "POST",
      data: { payload },
      nameEvent: "ChassisRentalLogger-StartChassisClock",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data.Errors;
  }
};
export const StopChassisClock = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_STOP_CHASSIS_CLOCK,
      method: "POST",
      data: { payload },
      nameEvent: "ChassisRentalLogger-StopChassisClock",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data.Errors;
  }
};
export const AssignInspectionChassisIn = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_ASSIGN_INPESCTION_CHASSIS,
      method: "POST",
      data: { payload },
      nameEvent: "ChassisRentalLogger-AssignInspectionChassisIn",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data.Errors;
  }
};

export const getChassisAssignedByAgrementId = async (agreementId) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_CHASSIS_ASSIGNED_BY_AGREEMENT_ID,
      method: "GET",

      params: { agreementId },
      nameEvent: "AgreementList-getChassisAssignedByAgrementId",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getChassisInspections = async (scacCodeOwner) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_INSPECTION_CHASSIS,
      method: "GET",
      params: { scacCodeOwner },
      nameEvent: "PendingInspect-getChassisInspections",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
