import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const CheckInChassisRental = async (payload) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_CHECK_IN_CHASSIS_RENTAL,
      method: "POST",
      data: { payload },
      nameEvent: "ChassisRentalLogger-CheckInChassisRental",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

