import ChassisRentalFieldRow from "./containers/ChassisRentalFieldRow/ChassisRentalFieldRow";
import CheckInOutContainer from "./containers/CheckInOutContainer/CheckInOutContainer";
import NotificationAlert from "./components/SnackBar/Snackbar";
import {
  CheckInOutProvider,
  useCheckInOutContext,
} from "./context/CheckInOutContext";
import CircularProgress from "@mui/material/CircularProgress";
import { SnackBarProvider } from "./context/SnackBarContext";
import { Container } from "@mui/material";

import "./root.css";
import "./assets/global.css";

function AppContent() {
  const { loading } = useCheckInOutContext();

  return (
    <>
      {" "}
      <section className="ChassisRentalLogger-Root-container">
        <Container>
          {loading && (
            <>
              <section className="ChassisRentalLogger-Root-loading">
                <CircularProgress
                  thickness={7}
                  className="ChassisRentalLogger-circular-progress"
                />
              </section>
            </>
          )}
          <ChassisRentalFieldRow />
          <CheckInOutContainer />
          <NotificationAlert />
        </Container>
      </section>
    </>
  );
}

export default function Root() {
  return (
    <SnackBarProvider>
      <CheckInOutProvider>
        <AppContent />
      </CheckInOutProvider>{" "}
    </SnackBarProvider>
  );
}
