import React from "react";
import {
  Typography,
  Paper,
  Button,
  TextField,
  LinearProgress,
  Autocomplete,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { GetYardItemsByScac } from "../../services/YardServices";

import "./CheckOut.css";
import { useCheckInOutContext } from "../../context/CheckInOutContext";
import { useSnackBar } from "../../context/SnackBarContext";

const CheckOut = () => {
  const {
    outgateDateTime,
    onlyChassisIngate,
    transactionPayload,
    setTransactionPayload,
    ownerScacCode,
    loading,
    setLoading,
    chassisAssignedToThisAgreement,
    chassisAssigned,
    setChassisAssigned,
    chassisAssignedToAntoherAgreement,
    agreementData,
    setChassisAssignedToAntoherAgreement,
  } = useCheckInOutContext();
  const { handleRenderSnackBar } = useSnackBar();
  const url =
    process.env.REACT_APP_IMAGES_BANK + "draypowerimages/app-bar-images/";
  const urlButtons =
    process.env.REACT_APP_IMAGES_BANK + "draypowerimages/btn-icons/";
  const [width, setWidth] = React.useState(window.innerWidth);
  const [chassisList, setChassisList] = React.useState([]);

  function handleChangeTypeOutgate(event) {
    setTransactionPayload({
      ...transactionPayload,
      outgate: {
        ...transactionPayload.outgate,
        transaction_type: event.target.value,
      },
    });
  }

  function handleSetChassisSelected(chassis) {
    let chassisAssignedToAgreement = false;
    let chassisAsignedToAnotherAgreement = false;
    if (chassis && chassisAssignedToThisAgreement.length > 0) {
      chassisAssignedToThisAgreement.map((chassisAssigned) => {
        if (
          chassisAssigned.ChassisNumber === chassis.chassis_number &&
          chassisAssigned.CarrierScacCode ===
            agreementData.CarrierContact.ScacCode
        ) {
          chassisAssignedToAgreement = true;
          chassisAsignedToAnotherAgreement = false;
        } else if (
          chassisAssigned.ChassisNumber === chassis.chassis_number &&
          chassisAssigned.stateInspection === "Pending" &&
          chassisAssigned.CarrierScacCode !==
            agreementData.CarrierContact.ScacCode
        ) {
          chassisAsignedToAnotherAgreement = true;
        }
      });
    }

    setChassisAssignedToAntoherAgreement(chassisAsignedToAnotherAgreement);
    setChassisAssigned(chassisAssignedToAgreement);

    setTransactionPayload({
      ...transactionPayload,
      outgate: {
        ...transactionPayload.outgate,
        chassis_number: chassis ? chassis.chassis_number : "",
        flip: chassis?.container_number ? true : false,
      },
    });
  }

  function getChassisList() {
    setLoading(true);
    handleSetChassisSelected(null);

    setChassisList([]);
    GetYardItemsByScac(ownerScacCode, transactionPayload.location).then(
      (data) => {
        if (data.data && data.data.container_chassis_items.length > 0)
          data.data.container_chassis_items.map((itemInYard) => {
            if (itemInYard.chassis_number) {
              setChassisList((prev) => [...prev, itemInYard]);
            }
          });
        else {
          handleRenderSnackBar(
            "No chassis available in this location",
            "error"
          );
          setChassisList([]);
        }
        setLoading(false);
      }
    );
  }

  React.useEffect(() => {
    if (ownerScacCode === null || transactionPayload.location === null) return;
    getChassisList();
  }, [ownerScacCode, transactionPayload.location]);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return (
    <>
      <Paper className="CheckOut-paper-container" elevation={12}>
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="CheckOut-title-container-grid"
            container
          >
            {width > 900 && (
              <Grid
                xs={0}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="CheckOut-leftIcon-grid"
              >
                <img
                  src={url + "Icono Out gate.png"}
                  alt="check-in"
                  className="CheckOut-left-image"
                />
              </Grid>
            )}
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="CheckOut-title-grid-item"
            >
              <Typography className="CheckOut-title-text">out gate</Typography>
            </Grid>

            {width > 900 && (
              <Grid
                xs={0}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                className="CheckOut-rigthIcon-grid"
              >
                <img
                  src={url + "Dibujo Out Gate.png"}
                  alt="check-in"
                  className="CheckOut-rigth-image"
                />
              </Grid>
            )}
          </Grid>{" "}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            {loading && <LinearProgress className="CheckOut-linearprogress" />}
          </Grid>{" "}
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="CheckOut-tabs-container-grid"
            container
            spacing={3}
          >
            <Grid
              xs={12}
              sm={4}
              md={6}
              lg={4}
              xl={4}
              className="CheckOut-tabs-button-grid-item"
            >
              <Button
                onClick={(e) => handleChangeTypeOutgate(e)}
                className={
                  transactionPayload.outgate.transaction_type === "bobtail"
                    ? "CheckOut-tab-button-selected"
                    : "CheckOut-tab-button"
                }
                fullWidth
                value="bobtail"
              >
                BOBTAIL
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              md={6}
              lg={4}
              xl={4}
              className="CheckOut-tabs-button-grid-item"
            >
              <Button
                fullWidth
                onClick={(e) => handleChangeTypeOutgate(e)}
                className={
                  transactionPayload.outgate.transaction_type === "chassis"
                    ? "CheckOut-tab-button-selected"
                    : "CheckOut-tab-button"
                }
                value="chassis"
                disabled={onlyChassisIngate}
              >
                CHASSIS ONLY
              </Button>
            </Grid>
            <Grid
              xs={12}
              sm={4}
              md={12}
              lg={4}
              xl={4}
              className="CheckOut-tabs-button-grid-item"
            >
              <Button
                onClick={(e) => handleChangeTypeOutgate(e)}
                className={
                  transactionPayload.outgate.transaction_type === "trailer"
                    ? "CheckOut-tab-button-selected"
                    : "CheckOut-tab-button"
                }
                fullWidth
                value="trailer"
                disabled
              >
                TRAILER
              </Button>
            </Grid>
          </Grid>{" "}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} container>
            <Grid
              container
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              display={"flow"}
            >
              {(transactionPayload.outgate.transaction_type === "bobtail" ||
                transactionPayload.outgate.transaction_type === "common") && (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="CheckIn-grid-container-input"
                >
                  <Autocomplete
                    disabled
                    disablePortal
                    id="combo-box-demo"
                    options={[]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Container Number"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-chassis-input"
              >
                <Autocomplete
                  disabled={
                    transactionPayload.outgate.transaction_type !== "chassis" ||
                    loading
                  }
                  disablePortal
                  id="combo-box-demo"
                  options={chassisList}
                  getOptionLabel={(option) =>
                    transactionPayload.outgate.chassis_number
                  } // Asegúrate de devolver un string
                  onChange={(event, newValue) =>
                    handleSetChassisSelected(newValue)
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography className="ChassisRentalLogger-CheckOut-chassis-text-option">
                        {option?.chassis_number}
                      </Typography>
                      {option?.container_number && (
                        <Typography className="ChassisRentalLogger-CheckOut-container-text-option">
                           with container {option.container_number}
                        </Typography>
                      )}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Chassis Number"
                      size="small"
                    />
                  )}
                />
              </Grid>{" "}
              {chassisAssigned &&
                transactionPayload.outgate.chassis_number &&
                chassisAssignedToAntoherAgreement && (
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="CheckIn-grid-chassis-input"
                  >
                    <Alert variant="outlined" severity="warning">
                      This chassis has been assigned to another agreement, if
                      you confirm the transaction, the chassis will be assigned
                      to be inspected.
                    </Alert>
                  </Grid>
                )}
              {!chassisAssigned &&
                transactionPayload.outgate.chassis_number &&
                !chassisAssignedToAntoherAgreement && (
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="CheckIn-grid-chassis-input"
                  >
                    <Alert variant="outlined" severity="warning">
                      This chassis has not been assigned to be inspected, if you
                      confirm the transaction, the chassis will be assigned to
                      be inspected.
                    </Alert>
                  </Grid>
                )}
            </Grid>

            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={5}
              xl={5}
              display={"flow"}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-container-empty-flip-buttons"
              >
                {(transactionPayload.outgate.transaction_type === "bobtail" ||
                  transactionPayload.outgate.transaction_type === "common") && (
                  <Grid
                    container
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    className="CheckIn-empty-loaded-grid-button"
                  >
                    <Button
                      disabled={
                        transactionPayload.outgate.transaction_type !==
                          "chassis" &&
                        transactionPayload.outgate.transaction_type !== "common"
                      }
                      variant="contained"
                      fullWidth
                      className={`CheckIn-empty-loaded-button ${
                        transactionPayload.outgate.empty
                          ? "CheckIn-empty-loaded-activated"
                          : ""
                      }`}
                      onClick={() =>
                        setTransactionPayload({
                          ...transactionPayload,
                          outgate: {
                            ...transactionPayload.outgate,
                            empty: !transactionPayload.outgate.empty,
                          },
                        })
                      }
                    >
                      <img
                        src={
                          urlButtons +
                          (transactionPayload.outgate.empty
                            ? "Empty.png"
                            : "Loaded.png")
                        }
                        alt="empty"
                        className="CheckIn-empty-loaded-image"
                      />
                    </Button>
                  </Grid>
                )}
                <Grid
                  container
                  xs={
                    transactionPayload.outgate.transaction_type === "chassis"
                      ? 12
                      : 6
                  }
                  sm={
                    transactionPayload.outgate.transaction_type === "chassis"
                      ? 12
                      : 6
                  }
                  md={
                    transactionPayload.outgate.transaction_type === "chassis"
                      ? 12
                      : 6
                  }
                  lg={
                    transactionPayload.outgate.transaction_type === "chassis"
                      ? 12
                      : 6
                  }
                  xl={
                    transactionPayload.outgate.transaction_type === "chassis"
                      ? 12
                      : 6
                  }
                  className="CheckIn-flip-grid-button"
                >
                  {" "}
                  <Button
                    disabled={
                      transactionPayload.outgate.transaction_type !==
                        "chassis" &&
                      transactionPayload.outgate.transaction_type !== "common"
                    }
                    variant="contained"
                    fullWidth
                    className={`CheckIn-flip-button ${
                      transactionPayload.outgate.flip
                        ? "CheckIn-flip-button-activated"
                        : ""
                    }`}
                    onClick={() =>
                      setTransactionPayload({
                        ...transactionPayload,
                        outgate: {
                          ...transactionPayload.outgate,
                          flip: !transactionPayload.outgate.flip,
                        },
                      })
                    }
                  >
                    {" "}
                    <img
                      src={
                        urlButtons +
                        (transactionPayload.outgate.flip
                          ? "Flip.png"
                          : "No flip.png")
                      }
                      alt="flip"
                      className={"CheckIn-flip-image"}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-ingate-date"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: "100%" }}
                    label="Out Gate Date"
                    inputFormat="yyyy-MM-dd"
                    value={outgateDateTime.date}
                    disabled
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>{" "}
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="CheckIn-grid-ingate-time"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    label="Out Gate Time"
                    sx={{ width: "100%" }}
                    value={outgateDateTime.time}
                    disabled
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="CheckIn-grid-container-confirm-button"
          >
            <Grid xs={12} sm={6} md={8} lg={9} xl={9}></Grid>
            <Grid xs={12} sm={6} md={4} lg={3} xl={3}>
              <Button
                fullWidth
                variant="contained"
                disabled
                className="CheckIn-confirm-button"
              >
                CONFIRM
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CheckOut;
