// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .full-height {
  height: 100%;
}

body .full-width {
  width: 100%;
}

body .fit-content {
  height: fit-content;
}

`, "",{"version":3,"sources":["webpack://./src/assets/global.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["body .full-height {\r\n  height: 100%;\r\n}\r\n\r\nbody .full-width {\r\n  width: 100%;\r\n}\r\n\r\nbody .fit-content {\r\n  height: fit-content;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
