import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import { useCheckInOutContext } from "../../context/CheckInOutContext";
import { GetYardLocationsByOwner } from "../../services/YardServices";
import { GetDriverByLicense } from "../../services/DriverServices";
import { useSnackBar } from "../../context/SnackBarContext";
import CachedIcon from "@mui/icons-material/Cached";
import Grid from "@mui/material/Unstable_Grid2";
import "./ChassisRentalFieldRow.css";
const ChassisRentalFieldRow = () => {
  const {
    transactionPayload,
    setTransactionPayload,
    setLoading,
    ownerScacCode,
    carrierScacCode,
  } = useCheckInOutContext();
  const { handleRenderSnackBar } = useSnackBar();
  const [yardsList, setYardsList] = React.useState([]);
  const [licenseButton, setLicenseButton] = React.useState(false);
  const handleChangeLocation = (event) => {
    setTransactionPayload({
      ...transactionPayload,
      location: event.target.value,
    });
  };

  function fetchYardsList() {
    GetYardLocationsByOwner(ownerScacCode).then((locations) => {
      locations.Data.map((location) => {
        setYardsList((prev) => [...prev, location.YardId.CodeName]);
      });
      setLoading(false);
    });
  }

  function handleReloadScreen() {
    const url = new URL(window.location.href);
    url.searchParams.delete("Chassis");
    window.location.href = url.toString();
  }

  function handleConsultLicense() {
    setLoading(true);
    GetDriverByLicense(transactionPayload.license.driver_license)
      .then((data) => {
        if (data.success) {
          const scacIsAuthorized = data.data.authorized_scacs
            .filter((scac) => {
              return scac.active;
            })
            .map((scac) => scac.scac_code);
          if (scacIsAuthorized.length === 0) {
            handleRenderSnackBar("Driver has no active contracts", "error");
            setLoading(false);
          } else {
            setTransactionPayload({
              ...transactionPayload,
              license: {
                ...transactionPayload.license,
                driver_name: data.data.first_name + " " + data.data.last_name,
                id: data.data.id,
                driver_license: data.data.license,
              },
            });
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function handleEvaluateLicenseButton() {
    if (
      transactionPayload.license.driver_license &&
      transactionPayload.license.driver_license.length >= 7 &&
      transactionPayload.license.driver_license.length <= 19
    )
      setLicenseButton(true);
    else setLicenseButton(false);
  }

  React.useEffect(() => {
    if (!ownerScacCode) return;
    fetchYardsList();
  }, [ownerScacCode]);

  React.useEffect(() => {
    handleEvaluateLicenseButton();
  }, [transactionPayload.license.driver_license]);

  return (
    <>
      <section className="ChassisRentalLoger-ChassisRentalFieldRow-container">
        <Grid container spacing={2} columns={16}>
          <Grid xs={0} sm={8} md={12} lg={14} xl={14} />
          <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
            <Button
              variant="contained"
              fullWidth
              className="ChassisRentalFieldRow-start-new"
              onClick={handleReloadScreen}
            >
              Start New
            </Button>
          </Grid>
          <Grid xs={16} sm={8} md={8} lg={2} xl={2}>
            {" "}
            <Button
              disabled
              variant="contained"
              color="primary"
              className="full-height ChassisRentalFieldRow-supra-button"
              fullWidth
            >
              YMS CONT
            </Button>
          </Grid>{" "}
          <Grid xs={16} sm={8} md={8} lg={2} xl={2}>
            <TextField
              fullWidth
              error={carrierScacCode?.length < 4 || !carrierScacCode}
              disabled
              className="ChassisRentalFieldRow-scaccode-textfield"
              value={carrierScacCode}
              label="SCAC"
              InputProps={{
                style: { borderRadius: 8 },
              }}
              onChange={(e) => {
                if (e.target.value.length > 4) return;
                setTransactionPayload({
                  ...transactionPayload,
                  license: {
                    ...transactionPayload.license,
                    scac_code: e.target.value.toUpperCase(),
                  },
                });
              }}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid xs={16} sm={8} md={8} lg={2} xl={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={transactionPayload.location}
                label="Location"
                disabled={yardsList.length === 0}
                onChange={handleChangeLocation}
                className="ChassisRentalFieldRow-location-select"
              >
                {yardsList.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={16} sm={8} md={8} lg={2} xl={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="License"
              variant="outlined"
              value={transactionPayload.license.driver_license}
              onChange={(e) => {
                setTransactionPayload({
                  ...transactionPayload,
                  license: {
                    ...transactionPayload.license,
                    driver_license: e.target.value.toUpperCase(),
                  },
                });
              }}
              size="small"
              InputProps={{
                style: { borderRadius: 8 },
              }}
            />
          </Grid>
          <Grid xs={16} sm={8} md={8} lg={2} xl={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleConsultLicense}
              disabled={!licenseButton}
              startIcon={<CachedIcon />}
              className="full-height ChassisRentalFieldRow-validate-license-button"
            >
              Validate
            </Button>
          </Grid>{" "}
          <Grid xs={16} sm={8} md={8} lg={4} xl={4}>
            <TextField
              fullWidth
              focused
              id="outlined-basic"
              label="Name"
              variant="outlined"
              size="small"
              disabled
              value={transactionPayload.license.driver_name || " "}
              InputProps={{
                style: { borderRadius: 8 },
              }}
            />
          </Grid>
          <Grid xs={16} sm={8} md={8} lg={2} xl={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Truck"
              variant="outlined"
              size="small"
              value={transactionPayload.license.truck}
              onChange={(e) => {
                let parsed = parseInt(e.target.value);
                if (isNaN(parsed)) {
                  parsed = "";
                }

                setTransactionPayload({
                  ...transactionPayload,
                  license: {
                    ...transactionPayload.license,
                    truck: parsed,
                  },
                });
              }}
              InputProps={{
                style: { borderRadius: 8 },
              }}
            />
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default ChassisRentalFieldRow;
