import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const GetYardItemsByScac = async (scac_code, location) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_YARD_ITEMS_BY_SCAC,
      method: "GET",
      params: { scac_code, location },
      nameEvent: "ChassisRentalLogger-GetYardItemsByScac",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetYardLocationsByOwner = async (ScacCode) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_YARD_LOCATIONS_BY_OWNER,
      method: "GET",
      params: { ScacCode },
      nameEvent: "ChassisRentalLogger-GetYardLocationsByOwner",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
