import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Backdrop, CircularProgress } from "@mui/material";
import CheckIn from "../CheckIn/CheckIn";
import CheckOut from "../CheckOut/CheckOut";
import "./CheckInOutContainer.css";
const CheckInOutContainer = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <CheckIn />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <CheckOut />
        </Grid>
      </Grid>
    </>
  );
};

export default CheckInOutContainer;
